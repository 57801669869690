<template>
	<div class="admin-page">
		<v-card
			color="background"
			elevation="0"
			:class="isMobile ? 'admin-page-card-mobile' : 'admin-page-card'"
		>
			<AdminNavigator />
			<v-card-text>
				<keep-alive>
					<router-view></router-view>
				</keep-alive>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import AdminNavigator from '@/views/admin/components/AdminNavigator';

export default {
	name: 'AdminPage',
	components: { AdminNavigator },
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 700;
		},
	},
};
</script>

<style scoped>
.admin-page {
	width: 100%;
	height: 100%;
}

.admin-page-card {
	min-height: 100vh;
	height: 100%;
	display: flex;
	flex-direction: row;
}
</style>
